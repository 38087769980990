<template>
  <b-sidebar
    id="add-new-user-sidebar"
    ref="sideBar"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    right
    shadow
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #header="{hide}">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h5 class="mb-0">
          {{ isUpdateParticipants ? 'Atualizar Participantes' : 'Importar arquivo' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer float-right"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
    </template>
    <template #footer="{hide}">
      <div class="p-1">
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <template #default="{ hide }">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <label for="file">Arquivo: (csv)</label>
          <b-form-file
            id="file"
            v-model="model.file"
            accept=".csv"
          />
          <download-helper v-if="isUpdateParticipants" :full-url="updateFileExample" />
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import DownloadHelper from '@/components/app/global/DownloadHelper.vue'

export default {
  name: 'PrizeDrawFileImport',
  directives: {
    Ripple,
  },
  components: {
    DownloadHelper,
    ValidationObserver,
  },
  props: {
    prizeDraw: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isUpdateParticipants: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      promotionsAvailable: [],
      model: {
        file: [],
      },
      updateFileExample: `${process.env.VUE_APP_URL}/file-examples/atualizacao_participante.csv`,
    }
  },
  methods: {
    onSubmit() {
      const data = new FormData()
      data.append('file', this.model.file)

      if (this.isUpdateParticipants) {
        this.updateParticipantsFile(data)
        return
      }

      this.$refs.refFormObserver.validate()
        .then(async () => {
          let method = 'import-comments'
          if (this.prizeDraw.promotion.settings.can_import_file) {
            method = 'import'
          }

          const response = await this.$http.post(`prize-draws/${this.prizeDraw.uuid}/${method}`, data)
          if (response && response.error_message) {
            this.$notify.error(response.error_message)
          } else {
            this.$notify.success(response.message)
          }
          this.model.file = null
          this.$refs.refFormObserver.reset()
          this.$emit('onModelSave', true)
        })
    },
    async updateParticipantsFile(data) {
      const response = await this.$http.post('participants/update', data)
      if (response && response.error_message) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success('Dados atualizados com sucesso.')
      this.model.file = null
      this.$refs.refFormObserver.reset()
      this.$emit('onModelSave', true)
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.$refs.refFormObserver.reset()
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <b-modal
    v-model="open"
    size="lg"
    title="Cadastro de Segmentos"
    ok-title="Salvar"
    cancel-title="Cancelar"
    hide-header-close
    @hide="cancelHide"
    @hidden="close"
    @close="close"
    @ok="handleSubmit"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <label>Arquivo JSON:</label>
      <b-form-file
        v-model="model.file"
        name="fileExample"
        accept=".json"
      ></b-form-file>
      <DownloadHelper :full-url="urlFileExample" />
    </form>
    <i2-divider />
    <b-row>
      <b-col>
        <Grid
          :fields="fields"
          :items="items"
          edit-button-label="edit"
          new-button-label="new"
          :show-search="false"
          :show-pagination="false"
        >
          <template #cell(action)="data">
            <i2-icon-button
              icon="Trash2Icon"
              variant="outline-danger"
              class="ml-1"
              @click="removeFilter(data.item)"
            />
          </template>
        </Grid>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>

import DownloadHelper from '@/components/app/global/DownloadHelper.vue'
import I2Divider from '@/components/i2/content/i2Divider.vue'

export default {
  name: 'PrizeDrawSegment',
  components: {
    I2Divider,
    DownloadHelper,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    prizeDraw: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      urlFileExample: '',
      model: {
        file: [],
      },
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-30',
        },
        {
          key: 'segments',
          label: 'Valor',
          class: 'w-60',
        },
        {
          key: 'winners',
          label: 'Vencedores',
          class: 'w-60',
        },
        {
          key: 'action',
          label: 'Ação',
          class: 'w-10',
        },
      ],
      items: [],
    }
  },
  watch: {
    isOpen(value) {
      this.open = value
      this.urlFileExample = `${process.env.VUE_APP_URL}/file-examples/PrizeDrawSegmentsExampleJson.json`
      if (value) {
        this.getSegments()
      }
    },
  },
  methods: {
    getSegments() {
      this.items = []
      this.$http.get(`prize-draws/${this.prizeDraw.uuid}/segments`).then(
        response => {
          if (response.length === 0) {
            return
          }
          this.items = response
        },
      )
    },
    cancelHide() {
      return false
    },
    close() {
      this.$emit('onClose', true)
    },
    async handleSubmit(bvModal) {
      bvModal.preventDefault()

      if (!(this.model.file instanceof File)) {
        this.$notify.error('Selecione um arquivo JSON.')
        return
      }
      const formData = new FormData()
      formData.append('file', this.model.file)
      formData.append('prize_draw_uuid', this.prizeDraw.uuid)
      const response = await this.$http.post(`prize-draws/${this.prizeDraw.uuid}/segments`, formData)
      this.$notify.success('Segmentos salvos com sucesso.')
      this.close()
    },
    removeFilter(item) {
      this.$bvModal.msgBoxConfirm(`Confirma a exclusão do segmento ${item.name}`, {
        title: 'Atenção!',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
        noCloseOnBackdrop: true,
      })
        .then(async mustRemove => {
          if (mustRemove) {
            await this.$http.delete(`prize-draws-segments/${item.uuid}`)
            this.getSegments()
          }
        })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <small>
    <a :href="fullUrl" target="_blank">
      {{ title }}
    </a>
  </small>
</template>
<script>
export default {
  name: 'DownloadHelper',
  props: {
    fullUrl: {
      required: true,
      type: String,
    },
    title: {
      required: false,
      default: 'Download de Exemplo',
      type: String,
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <b-sidebar
    id="add-new-user-sidebar"
    ref="sideBar"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    right
    shadow
    width="500px"
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #header="{hide}" >
      <div class="d-flex justify-content-between align-items-center w-100" >
        <h5 class="mb-0">
          Cadastro de Sorteios
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer float-right"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
    </template>
    <template #footer="{hide}" >
      <div class="p-1" >
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <template #default="{ hide }">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-vue-select
                v-model="model.promotion"
                label="Promoção"
                :options="promotionsAvailable"
                rules="required"
                placeholder=""
                name="promotion"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.name"
                rules="required"
                label="Nome"
                placeholder=""
                name="name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.start_date"
                rules="required"
                type="datetime-local"
                label="Data Inícial"
                placeholder=""
                name="start_date"
              />
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.end_date"
                rules="required"
                type="datetime-local"
                label="Data Final"
                placeholder=""
                name="end_date"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input-date
                v-model="model.prize_draw_date"
                rules="required"
                label="Data do Sorteio"
                placeholder=""
                name="prize_draw_date"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.start_serie"
                rules="required"
                label="Início da Série"
                placeholder="Inicio da série"
                type="number"
                name="start_serie"
              />
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.end_serie"
                rules="required"
                label="Fim da Série"
                type="number"
                placeholder="Fim da série"
                name="end_serie"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.total_winners"
                label="Número de vencedores"
                placeholder="número máximo de vencedores"
                type="number"
                min="1"
                rules="required|min_value:1"
                name="total_winners"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.valid_codes"
                label="Códigos Válidos (separar valores por ',')"
                placeholder=""
                name="valid_codes"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import moment from 'moment-timezone'

export default {
  name: 'PrizeDrawForm',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
  },
  props: {
    prizeDraw: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      promotionsAvailable: [],
      model: {
        name: '',
        uuid: null,
        start_date: null,
        end_date: null,
        prize_draw_date: null,
        start_serie: null,
        end_serie: null,
        valid_codes: null,
        total_winners: 1,
        promotion: {
          value: null,
          text: '',
        },
      },
    }
  },
  watch: {
    prizeDraw(val) {
      this.resetModel()
      if (val.uuid) {
        this.getData(val.uuid)
      }
    },
  },
  async mounted() {
    await this.getPromotions()
  },
  methods: {
    async getPromotions() {
      const response = await this.$http.get('promotions')
      this.promotionsAvailable = response.map(promotion => ({
        value: promotion.uuid,
        text: promotion.name,
      }))
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          data.prize_draw_date = moment(data.prize_draw_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
          if (this.model.uuid) {
            response = await this.$http.put(`prize-draws/${this.model.uuid}`, data)
          } else {
            response = await this.$http.post(`prize-draws/promotions/${this.model.promotion.value}`, data)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$notify.success('Sorteio salvo com sucesso.')
          this.resetModel()
          this.$refs.refFormObserver.reset()
          this.$emit('onModelSave', true)
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.resetModel()
      this.$refs.refFormObserver.reset()
    },
    async getData(uuid) {
      const response = await this.$http.get(`prize-draws/${uuid}`)
      this.model = {
        ...response,
        promotion: this.promotionsAvailable.find(val => val.value === response.promotion.uuid),
        start_serie: response.settings.start_serie,
        end_serie: response.settings.end_serie,
        valid_codes: response.settings.valid_codes.join(','),
        prize_draw_date: response.draw_date,
      }
      this.model.end_date = moment(response.end_date, 'DD/MM/YYYY HH:mm:SS').format('YYYY-MM-DD HH:mm:SS')
      this.model.start_date = moment(response.start_date, 'DD/MM/YYYY HH:mm:SS').format('YYYY-MM-DD HH:mm:SS')
    },
    resetModel() {
      this.model = {
        name: '',
        uuid: null,
        start_date: null,
        end_date: null,
        prize_draw_date: null,
        start_serie: null,
        end_serie: null,
        valid_codes: null,
        total_winners: 1,
        promotion: {
          value: null,
          text: '',
        },
      }
    },
  },
}
</script>

<style scoped>

</style>

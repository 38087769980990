<template>
  <div v-if="segments.length > 0">
    <i2-form-vue-select
      v-model="segment"
      class="filter-input"
      :options="segments"
      placeholder="Segmento"
      name="segment"
      label="Segmento"
    >
    </i2-form-vue-select>
  </div>
</template>
<script>
export default {
  name: 'PrizeDrawSegmentCombo',
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
    prizeDraw: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      segments: [],
      segment: null,
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.segment = this.segments.find(item => item.value === val)
      }
    },
    segment(val) {
      this.$emit('input', val?.value || '')
      this.$emit('segment', val?.item || '')
    },
  },
  mounted() {
    this.getPrizeDrawSegments()
    if (this.value) {
      this.segment = this.segments.find(item => item.value === this.segment)
    }
  },
  methods: {
    async getPrizeDrawSegments() {
      const response = await this.$http.get(`/prize-draws/${this.prizeDraw}/segments`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }

      this.segments = response.map(item => {
        let status = ''
        if (item.status === 'done') {
          status = '✅'
        }
        return {
          value: item.id,
          text: `${status} ${item.name} - (${item.id}) - (${item.winners} vencedores)`,
          item,
        }
      })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <b-container>
    <b-modal
      v-model="showModal"
      v-b-modal.modal-prevent-closing
      :title="title"
      cancel-title="Fechar"
      no-close-on-backdrop
      ok-title="Salvar"
      size="xl"
      scrollable
      @change="onHide"
      @ok="handleSubmit"
      @cancel="close"
    >
      <b-alert
        variant="warning"
        :show="prizeDraw.status === 2"
      >
        <div class="alert-body">
          <span>O sorteio já está homologado. Portanto os prêmios não podem ser alterados.</span>
        </div>
      </b-alert>
      <b-card>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormPrizesObserver"
        >
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(addPrize)"
          >
            <b-row class="align-items-center">
              <b-col md="2">
                <i2-form-input
                  v-model="model.start"
                  rules="required"
                  label="Início"
                  placeholder="Inicio"
                  type="number"
                  name="start"
                />
              </b-col>
              <b-col md="2">
                <i2-form-input
                  v-model="model.end"
                  rules="required"
                  label="Fim"
                  placeholder="Fim"
                  type="number"
                  name="end"
                />
              </b-col>
              <b-col md="4">
                <i2-form-input
                  v-model="model.prize"
                  rules="required"
                  label="Prêmio"
                  placeholder="Prêmio"
                  name="prize"
                />
              </b-col>
              <b-col md="4">
                <i2-form-input
                  v-model="model.description"
                  rules=""
                  label="Descrição"
                  placeholder="Descrição"
                  name="description"
                />
              </b-col>
            </b-row>
            <b-row align-content="center">
              <b-col md="5">
                <i2-form-input-money
                  v-model="model.value"
                  rules=""
                  label="Valor do Prêmio"
                  name="value"
                />
              </b-col>
              <b-col md="5">
                <prize-draw-segment-combo
                  v-if="prizeDraw.uuid"
                  v-model="model.segment"
                  :prize-draw="prizeDraw.uuid"
                  @segment="onCurrentSegment"
                />
              </b-col>
              <b-col md="2" align-self="center">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  @click="addPrize"
                >
                  <feather-icon icon="PlusIcon"/>
                  Adicionar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <i2-divider />
        <Grid
          :fields="fields"
          :items="items"
          :show-search="false"
          edit-button-label="edit"
          new-button-label="new"
          :show-pagination="false"
        >
          <template v-slot:search>
            <b-row class="align-items-center">
              <b-col md="3" sm="3">
                <i2-form-vue-select v-model="filter.sort_field" :options="columnShort" name="sort_field" label="Campo" />
              </b-col>
              <b-col md="3" sm="3">
                <i2-form-vue-select v-model="filter.sort_order" :options="columnOrder" name="sort_order" label="Ordem" />
              </b-col>
              <b-col>
                <b-button
                  variant="secondary"
                  type="button"
                  @click="getPrizes"
                >
                  <FeatherIcon icon="SearchIcon" />
                  <span
                    class="text-nowrap"
                  />
                </b-button>
              </b-col>
            </b-row>
          </template>
          <template #cell(value)="data">
            {{ data.item.value | toCurrency }}
          </template>

          <template #cell(segment)="data">
            {{ getSegmentItem(data.item) }}
          </template>

          <template #cell(action)="data">
            <b-button-group justify>
              <b-button
                variant="outline-warning"
                class="btn-icon"
                @click="editPrize(data.item, data.index)"
              >
                <feather-icon icon="EditIcon"/>
              </b-button>
              <b-button
                variant="danger"
                class="btn-icon"
                @click="deletePrize(data.item, data.index)"
              >
                <feather-icon icon="Trash2Icon"/>
              </b-button>
            </b-button-group>
          </template>
        </Grid>
      </b-card>
    </b-modal>
  </b-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { BAlert } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import PrizeDrawSegmentCombo from '@/components/app/prize-draw/PrizeDrawSegmentCombo.vue'
import I2Divider from '@/components/i2/content/i2Divider.vue'

export default {
  name: 'PrizeDrawRegisterPrizes',
  components: {
    I2Divider,
    PrizeDrawSegmentCombo,
    ValidationObserver,
    BAlert,
  },
  props: {
    prizeDraw: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      columnShort: [
        {
          text: 'Descrição',
          value: 'prize_draw_prizes.prize',
        },
        {
          text: 'Segmento',
          value: 'prize_draw_segments.name',
        },
      ],
      columnOrder: [
        {
          text: 'Crescente',
          value: 'asc',
        },
        {
          text: 'Decrescente',
          value: 'desc',
        },
      ],
      filter: {
        sort_field: null,
        sort_order: null,
      },
      showModal: false,
      title: '',
      items: [],
      model: {
        start: 1,
        end: null,
        prize: '',
        description: '',
        value: 0,
        segment: '',
      },
      currentSegment: {},
      fields: [
        {
          key: 'start',
          label: 'Início',
          class: 'w-10',
        },
        {
          key: 'end',
          label: 'Fim',
          class: 'w-10',
        },
        {
          key: 'prize',
          label: 'Prêmio',
          class: 'w-10',
        },
        {
          key: 'description',
          label: 'Descrição',
          class: 'w-10',
        },
        {
          key: 'value',
          label: 'Valor',
          class: 'w-10',
        },
        {
          key: 'segment',
          label: 'Segmento',
          class: 'w-30',
        },
        {
          key: 'action',
          label: 'Ação',
          class: 'w-10',
        },
      ],
    }
  },
  watch: {
    async value(value) {
      if (value) {
        this.filter.sort_field = this.columnShort[0]
        this.filter.sort_order = this.columnOrder[0]
        this.title = `Sorteio: ${this.prizeDraw.name} - ${this.prizeDraw.promotion.name} - Total de Vencedores: ${this.prizeDraw.total_winners}`
        await this.getPrizes()
        this.currentSegment = null
      }
      this.showModal = value
    },
  },
  methods: {
    resetModel() {
      this.model = {
        start: 1,
        end: null,
        prize: '',
        description: '',
        value: 0,
        segment: '',
      }
    },
    onCurrentSegment(segment) {
      this.currentSegment = segment
    },
    close() {
      this.resetModel()
      this.items = []
      this.$emit('onClose')
    },
    updateStartValue() {
      let lastValue = 0
      this.items.forEach(prizeItem => {
        lastValue = prizeItem.end
      })
      this.model.start = (Number(lastValue) + 1)
    },
    onHide() {
      this.$emit('onClose', true)
    },
    addPrize() {
      this.$refs.refFormPrizesObserver.validate().then(isValid => {
        if (!isValid) {
          return
        }
        if (this.currentSegment && this.currentSegment.name) {
          this.model.segment = {
            name: this.currentSegment.name,
            id: this.currentSegment.id,
          }
        }
        console.log('tem uuid -> ', this.model.uuid)
        if (this.model.uuid) {
          let index = this.items.findIndex(item => item.uuid === this.model.uuid)
          if (index >= 0) {
            this.$set(this.items, index, { ...this.model })
          }
        } else {
          this.items.push(this.model)
        }
        this.resetModel()
        this.updateStartValue()
        this.$refs.refFormPrizesObserver.reset()
      })
    },
    deletePrize(prize, index) {
      const items = []
      this.items.forEach((prizeItem, indexItem) => {
        if (indexItem !== index) {
          items.push(prizeItem)
        }
      })
      this.items = items
      this.updateStartValue()
    },
    validate() {
      if (this.model.end === '' || this.model.prize === '') {
        this.$notify.error('Todos os campos são de preenchimento obrigatório.')
        return false
      }

      return true
    },
    isDisabled(index) {
      const total = (index + 1)
      const totalItems = this.items.length
      return total !== totalItems
    },
    validatePrizesRegistry() {
      if (this.items.length === 0) {
        return true
      }
      const totalWinners = this.prizeDraw.total_winners
      let lastItem = null
      this.items.forEach(prizeItem => {
        lastItem = prizeItem
      })

      return true
    },
    async handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (!this.validatePrizesRegistry()) {
        return false
      }

      let items = this.items
      if (this.items.length !== 0) {
        // eslint-disable-next-line array-callback-return
        items = this.items.map(item => {
          if (!item.uuid) {
            item.uuid = uuidv4()
          }
          item.segment_id = item.segment?.id
          delete item.segment
        })
      }

      const params = {
        uuid_prize_draw: this.prizeDraw.uuid,
        prizes: this.items,
      }

      const response = await this.$http.post(`/prize-draws/${this.prizeDraw.uuid}/prizes`, params)
      if (response.error_message) {
        this.$notify.error(response.error_message)
        return false
      }
      this.$notify.success('Prêmios salvos com sucesso.')
      this.close()

      return true
    },
    async getPrizes() {
      this.items = await this.$http.get(`/prize-draws/${this.prizeDraw.uuid}/prizes?sort_order=${this.filter.sort_order.value}&sort_field=${this.filter.sort_field.value}`)
      this.updateStartValue()
    },
    editPrize(prize) {
      this.model = { ...prize }
      this.model.segment = prize.segment?.id
    },
    getSegmentItem(item) {
      if (item.segment && item.segment.name) {
        return `${item.segment.name} (${item.segment.id})`
      }
    },
  },
}
</script>

<style scoped>

</style>
